import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        services: [],
        translated: false,
        timeframesPopup: false,
        timeframesForceService: null,
        timeframesOnSelectedFn: null
    },

    getters: {
        byId: state => serviceId => state.services.find(s => s.id === serviceId),
        services: state => state.services,
        timeframesPopup: state => state.timeframesPopup,
        timeframesForceService: state => state.timeframesForceService,
        timeframesOnSelectedFn: state => state.timeframesOnSelectedFn,
        orderableServices: state => state.services.filter(service => {
            return ['fastlane', 'self', 'slot'].indexOf(service.type) !== -1
        })
    },

    mutations: {
        timeframesPopup(state, data) {
            state.timeframesPopup = data.show
            state.timeframesOnSelectedFn = data.show && data.onSelectedFn
            state.timeframesForceService = data.serviceId
        },

        reset(state) {
            state.services = []
        }
    },

    actions: {
        async services({ state }, data) {
            // TODO: refacto du composant de traduction pour renvoyer la langue et non pas un boolean
            data.translate = data.translate && this.getters['ln/current']
            if (state.services.length && !data.force && (!data.translate || state.translated === data.translate)) {
                return state.services
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/services`, {
                params: {
                    include_translation: !!data.translate,
                    include_advanced: true
                }
            })
            state.translated = data.translate
            state.services = res.data.filter(s => !s.disabled)
            return res.data
        },

        async get({ dispatch }, { id, slug }) {
            const services = await dispatch('services', { slug })
            return services.find(s => s.id === id)
        },

        async orderModes({ dispatch }, data) {
            const services = await dispatch('services', { slug: data.slug })
            const service = services.find(s => s.id === data.serviceId)
            return (service?.order_modes || []).map(mode => ({
                id: mode
            }))
        },

        async translate(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/services/${data.id}/i18n`)
            return res.data
        },

        async advanced(context, { service_id, translate }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced`, {
                params: {
                    include_translation: translate
                }
            })
            return res.data
        },

        async advancedStock(context, { service_id }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced/stock/remaining`)
            return res.data
        },

        async advancedOpen(context, { service_id, include_salepoint_schedules }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced/open`, {
                params: {
                    include_salepoint_schedules
                }
            })
            return res.data
        },

        async advancedSave(context, { slug, quantity, options }) {
            return Vue.prototype.$api.put(`/me/salepoints/${slug}/cart/advanced`, {
                quantity,
                options
            })
        },

        async listTimeframesCalendar(context, { service_id, date, product_ids }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced/timeframes/calendar`, {
                params: {
                    date,
                    with_stock_product_ids: product_ids,
                    include_stock: true
                }
            })
            return res.data || []
        },

        async listAvailableDates(context, { service_id, date, product_ids }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced/timeframes/dates`, {
                params: {
                    date,
                    with_stock_product_ids: product_ids,
                    include_stock: true
                }
            })
            return res.data
        },

        async getProductStock(context, { service_id, product_id, dates, quantity }) {
            const res = await Vue.prototype.$api.get(`/services/${service_id}/advanced/timeframes/products/${product_id}/stock`, {
                params: {
                    dates,
                    quantity
                }
            })
            return res.data
        }
    }
}
