<template>
    <v-switch
        v-show="ln !== salepoint.lang"
        v-model="active"
        color="primary"
        single-line
        hide-details
        class="mt-1 my-2 primary--text"
        :class="{ 'acc-switch-disabled-coloured': !active }"
        :disabled="loading"
        :loading="loading">
        <template #label>
            <span class="primary--text">{{ $t('productlist.translate') }}</span>
        </template>
    </v-switch>
</template>

<script>
export default {
    name: 'acs-product-translate',
    props: {
        loading: { type: Boolean },
        value: { type: Boolean }
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        ln() {
            return this.$store.getters['ln/current']
        },
        active: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        active(v) {
            const lang = v
                ? this.ln
                : this.salepoint.lang

            this.$store.commit('prod/translate', { active: v })
            this.$emit('change', { active: v, lang: lang })
        },
        ln() {
            // lorsque la langue sélectionnée change, on reset
            // le switch de traduction
            this.active = false
            // puis on le réactive
            window.setTimeout(() => (this.active = true), 500)
        }
    },
    mounted() {
        this.active = this.$store.getters['prod/translate']
    }
}
</script>

<style lang="sass">
.theme--light.v-input--switch .v-input--switch__track
    color: inherit !important
</style>
