<template>
    <v-navigation-drawer app temporary fixed right
        class="sidebar"
        width="304"
        v-model="drawer">
        <acs-base-menu v-model="drawer" />
        <div v-if="!isOnlyTerminal">
            <v-list-item target="_blank" :href="$config.links.support">
                <v-list-item-action>
                    <v-icon color="primary">mdi-linkedin</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('base.support') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="dataPolicyUrl">
                <v-list-item-action>
                    <v-icon color="primary">$vuetify.icons.basePolicy</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('shared-front:base.policy') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item :href="$config.links.website">
                <v-list-item-content>
                    <v-list-item-title class="grey--text">{{ $t('home.powered') }}</v-list-item-title>
                    <v-list-item-subtitle>v{{ $config.version }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <img :src="`${$config.os}/assets/accessing-logo-rgb.svg`" width="155" />
                </v-list-item-action>
            </v-list-item>
        </div>
        <acs-ticket-x v-if="isOnlyTeam" />
    </v-navigation-drawer>
</template>

<script>
import AcsBaseMenu from '@/components/base/AcsBaseMenu'
import AcsTicketX from '@/components/AcsTicketX'

export default {
    name: 'acs-base-menu-drawer',
    components: {
        AcsBaseMenu,
        AcsTicketX
    },
    props: {
        value: { type: Boolean }
    },
    computed: {
        drawer: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        dataPolicyUrl() {
            return this.$store.getters['pub/url']('/gdpr-cookies-gestion-des-donnees')
        },

        salepoint() {
            return this.$store.getters['sp/current']
        },

        isOnlyTerminal() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
        },

        isOnlyTeam() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'team')
        }
    }
}
</script>

<style lang="sass">
.sidebar .v-navigation-drawer__content
    display: flex !important
    flex-direction: column !important

.v-footer
    span
        letter-spacing: 1.5px
        text-transform: uppercase
        font-weight: 600
        font-size: 10px
        margin-bottom: -32px

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none)
  .sidebar
    /* The hack for Safari */
    height: -webkit-fill-available !important

</style>
