<template>
    <v-bottom-sheet v-model="show" max-width="700" scrollable>
        <template #activator="{ on }">
            <acs-slot-btn
                v-on="on"
                :disabled="disabled"
                :color="color"
                :label="service.name ? $options.filters.quickTranslate(service, 'name', true) : $t('salepointtable.call.btn')"
                icon="$vuetify.icons.waitCall" />
        </template>
        <v-card>
            <v-card-title class="flex-nowrap">
                <v-icon color="primary" size="34" class="pr-5">mdi-alert-circle-outline</v-icon>
                <div class="pr-5 flex-grow-1">{{ $t('salepointtable.call.pending') }}</div>
                <v-icon @click="show = false" class="pl-5">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-form v-model="valid">
                    <v-card-text>
                        <v-textarea
                            color="primary"
                            :label="$t('salepointtable.call.message')"
                            :placeholder="$t('salepointtable.call.more')"
                            persistent-placeholder
                            v-model="message"
                            :rules="[$ranged(null, max)]"
                            :counter="max"
                            outlined />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            block
                            rounded
                            color="primary"
                            class="acs-call--btn"
                            @click="select"
                            :disabled="!valid">
                            {{ $t('salepointtable.call.validate') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
import AcsSlotBtn from '@/components/slot/AcsSlotBtn'

export default {
    name: 'acs-slot-call-btn',
    props: {
        value: { type: Boolean },
        disabled: { type: Boolean },
        color: { type: String, default: 'primary' },
        cls: { type: String },
        service: { type: Object }
    },
    components: { AcsSlotBtn },
    data: () => ({
        valid: false,
        max: 50,
        message: null
    }),
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        select() {
            this.$emit('selected', {
                type: 'call',
                message: this.message
            })
            this.message = null
            this.show = false
        }
    }
}
</script>
